import smoothscroll from 'smoothscroll-polyfill'

import 'normalize.css'
import './fonts.scss'
import './theme.scss'
import './style.scss'

// partials css
import './partials/header.scss'
import './partials/form.scss'
import './partials/dog.scss'
import './partials/footer.scss'
import './partials/blob.scss'

// components
import './styles/components.scss'

smoothscroll.polyfill()

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
		anchor.addEventListener('click', function (e) {
			e.preventDefault()

			const id = this.getAttribute('href')
			const target = document.querySelector(id)
			const offset = 100

			window.scrollTo({
				top: target.getBoundingClientRect().top + window.scrollY - offset,
				behavior: 'smooth',
			})
		})
	})

	const debounceTimer = null
	headerSticky()
	document.addEventListener('scroll', () => {
		if (debounceTimer) {
			clearTimeout(debounceTimer)
		}

		debounceTimer = setTimeout(() => {
			headerSticky()
		}, 100)
	})
})

function headerSticky() {
	const header = document.querySelector('#header')

	if (window.scrollY > 0) {
		header.classList.add('header-sticky')
	} else {
		header.classList.remove('header-sticky')
	}
}
